export const SLOT_NAME_APPEND = 'append'
export const SLOT_NAME_BUTTON_CONTENT = 'button-content'
export const SLOT_NAME_DEFAULT = 'default'
export const SLOT_NAME_DESCRIPTION = 'description'
export const SLOT_NAME_FIRST = 'first'
export const SLOT_NAME_FOOTER = 'footer'
export const SLOT_NAME_HEADER = 'header'
export const SLOT_NAME_LABEL = 'label'
export const SLOT_NAME_LEAD = 'lead'
export const SLOT_NAME_PREPEND = 'prepend'
export const SLOT_NAME_TEXT = 'text'
export const SLOT_NAME_TITLE = 'title'
